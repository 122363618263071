import { Link } from "gatsby";
import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";
import GlobalContext from "../../context/GlobalContext";

const Footer = () => {
  const gContext = useContext(GlobalContext);

  const contactClassName =
    gContext.footer.theme === "dark" ? "gr-text-color" : "text-primary";

  const linkClassName =
    gContext.footer.theme === "dark"
      ? "gr-text-color gr-hover-text-green"
      : "gr-text-color gr-hover-text-blue";

  const currentYear = new Date().getFullYear();

  const iconClassName =
    gContext.footer.theme === "dark"
      ? "gr-text-color"
      : "gr-text-color gr-hover-text-blue";

  const copyClassName =
    gContext.footer.theme === "dark"
      ? "gr-text-color-opacity"
      : "gr-text-color";

  return (
    <>
      <div
        className={`footer-section ${
          gContext.footer.theme === "dark"
            ? "bg-blackish-blue dark-mode-texts"
            : ""
        }`}
      >
        <Container>
          <div className="footer-top pt-14 pt-lg-25 pb-lg-19">
            <Row>
              <Col xs="6" lg="2">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="footer-title gr-text-11 mb-7">
                    <Trans>footer.t1</Trans>
                  </p>
                  <ul className="footer-list list-unstyled">
                    <li className="py-2">
                      <Link to="/" className={`gr-text-9 ${linkClassName}`}>
                        <Trans>footer.shortlink-home</Trans>
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="/#features"
                        className={`gr-text-9 ${linkClassName}`}
                      >
                        <Trans>footer.shortlink-features</Trans>
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="#prices"
                        className={`gr-text-9 ${linkClassName}`}
                      >
                        <Trans>footer.shortlink-pricing</Trans>
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="https://app.trustify.ch/#/auth/login"
                        className={`gr-text-9 ${linkClassName}`}
                      >
                        <Trans>footer.shortlink-login</Trans>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs="6" lg="2">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="footer-title gr-text-11 mb-7">
                    <Trans>footer.t4</Trans>
                  </p>
                  <ul className="footer-list list-unstyled">
                    <li className="py-2">
                      <Link
                        to="/contact"
                        className={`gr-text-9 ${linkClassName}`}
                      >
                        <Trans>footer.support-contact</Trans>
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="https://trustify.tawk.help/"
                        target="_blank"
                        rel="noreferrer"
                        className={`gr-text-9 ${linkClassName}`}
                      >
                        <Trans>footer.support-helpdesk</Trans>
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="https://status.trustify.ch/"
                        target="_blank"
                        rel="noreferrer"
                        className={`gr-text-9 ${linkClassName}`}
                      >
                        <Trans>footer.support-status</Trans>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs="6" lg="2">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="footer-title gr-text-11 mb-7">
                    <Trans>footer.t5</Trans>
                  </p>
                  <ul className="footer-list list-unstyled">
                    <li className="py-2">
                      <Link
                        to="/partner"
                        className={`gr-text-9 ${linkClassName}`}
                      >
                        <Trans>footer.service-partner</Trans>
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="/compare-provenexpert"
                        className={`gr-text-9 ${linkClassName}`}
                      >
                        <Trans>footer.service-enterprise</Trans>
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="/compare-trustpilot"
                        className={`gr-text-9 ${linkClassName}`}
                      >
                        <Trans>footer.service-integration</Trans>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs="6" lg="3" className="pl-lg-15">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="footer-title gr-text-11 mb-7">
                    <Trans>footer.t6</Trans>
                  </p>
                  <ul className="footer-list list-unstyled">
                    <li className="py-2">
                      <Link
                        to="https://trustify.ch/imprint"
                        target="_blank"
                        rel="noreferrer"
                        className={`gr-text-9 ${linkClassName}`}
                      >
                        <Trans>footer.legal-imprint</Trans>
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="https://trustify.ch/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                        className={`gr-text-9 ${linkClassName}`}
                      >
                        <Trans>footer.legal-pp</Trans>
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link
                        to="https://trustify.ch/terms-conditions"
                        target="_blank"
                        rel="noreferrer"
                        className={`gr-text-9 ${linkClassName}`}
                      >
                        <Trans>footer.legal-terms</Trans>
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col sm="6" lg="3">
                <div className="single-footer mb-13 mb-lg-9">
                  <p className="footer-title gr-text-11 mb-7">
                    <Trans>footer.t7</Trans>
                  </p>
                  <ul className="footer-list list-unstyled">
                    <li className="py-2">
                      <a
                        className={`gr-text-9 font-weight-bold hover-underline active ${contactClassName}`}
                        href="mailto:info@trustify.ch"
                      >
                        <Trans>footer.email</Trans>
                      </a>
                    </li>
                    <li className="py-2">
                      <a
                        className={`gr-text-9 font-weight-bold hover-underline active ${contactClassName}`}
                        href="https://trustify.tawk.help/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Trans>footer.ticket</Trans>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          <div className="copyright-area border-top py-9">
            <Row className="align-items-center">
              <Col lg="6">
                <p
                  className={`copyright-text gr-text-11 mb-6 mb-lg-0 text-center text-lg-left ${copyClassName}`}
                >
                  © {currentYear} Copyright. All Rights Reserved. Made with
                  <i
                    className={`icon icon-heart-2-2 ${
                      gContext.footer.theme === "dark"
                        ? "text-sky-blue"
                        : "text-red"
                    } align-middle ml-2`}
                  ></i>{" "}
                  in Switzerland & Germany
                </p>
              </Col>
              <Col lg="6" className="text-center text-lg-right">
                <ul className="social-icons list-unstyled mb-0">
                  <li className="ml-7">
                    <Link
                      to="https://twitter.com/trustifych"
                      target="_blank"
                      rel="noreferrer"
                      className={iconClassName}
                    >
                      <i className="icon icon-logo-twitter"></i>
                    </Link>
                  </li>
                  <li className="ml-7">
                    <Link
                      to="https://www.facebook.com/trustifych/"
                      target="_blank"
                      rel="noreferrer"
                      className={iconClassName}
                    >
                      <i className="icon icon-logo-facebook"></i>
                    </Link>
                  </li>
                  {/*
                  <li className="ml-7">
                    <Link to="/" className={iconClassName}>
                      <i className="icon icon-instant-camera-2"></i>
                    </Link>
                  </li>
                  <li className="ml-7">
                    <Link to="/" className={iconClassName}>
                      <i className="icon icon-logo-linkedin"></i>
                    </Link>
                  </li>
                  */}
                </ul>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
