import React from "react";
import { Link } from "gatsby";

import imgL1Logo from "../../assets/image/logo-black-s.png";
import imgL1LogoWhite from "../../assets/image/logo-white-s.png";

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`d-block ${className}`} {...rest}>
      {white ? (
        <img src={imgL1LogoWhite} alt="Trustify" />
      ) : (
        <img src={imgL1Logo} alt="Trustify" />
      )}
    </Link>
  );
};

export default Logo;
